<template>
  <div class="geolocation-container">
    <button
      :class="['button-geolocation', { tracking }]"
      title="Me localiser"
      @click.prevent="toggleTracking"
    >
      <i class="icon" />
    </button>
  </div>
</template>

<script>

import mapService from '@/services/map-service';

export default {
  name: 'Geolocation',

  data() {
    return {
      tracking: false,
    };
  },

  methods: {
    toggleTracking() {
      this.tracking = !this.tracking;
      mapService.toggleGeolocation(this.tracking);
    },
  }
};
</script>